<template>
  <div>
    <div class="content">
      <div class="post" v-for="item in listData" :key="item.articleID">
        <div class="img-container" @click="clickLink(item.articleID)">
          <img :src="VUE_APP_BASE_API + item.titleImage" />
        </div>
        <div class="info-container">
          <div class="info">
            <div class="time">{{ item.create_time | formatDateC }}</div>
            <div class="title">
              <span @click="clickLink(item.articleID)">{{ item.title }}</span>
            </div>
            <div class="describe">{{ item.summary }}</div>
          </div>
        </div>
      </div>
      <div v-if="more || loading" class="border" @click="addMore">
        <div class="more" v-show="more">加载更多</div>
        <div v-show="loading" class="more">加载中……</div>
      </div>
      <div v-if="noMore" class="noMore">没有更多了</div>
    </div>
    <div class="footer">
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >鄂ICP备2022003829号</a
      >
    </div>
  </div>
</template>

<script>
import { VUE_APP_BASE_API } from "@/utils";
import { _GetArticleList } from "@/api/home.js";
export default {
  name: "Life",

  data() {
    return {
      // 列表请求数据
      listData: [],
      // loading
      loading: false,
      noMore: false,
      more: true,
      VUE_APP_BASE_API,
      // 生活查询条件
      queryCateL: {
        articleType: 2,
        page: 1,
        size: 3,
      },
    };
  },

  methods: {
    // 点击文章详情
    clickLink(id) {
      this.$router.push({ name: "detailPage", params: { id } });
    },
    // 获取生活文章列表
    async getArticleL() {
      this.loading = true;
      this.more = false;
      try {
        const res = await _GetArticleList(this.queryCateL);
        if (res.success) {
          this.loading = false;
          this.listData = [...this.listData, ...res.data];
          if (res.data.length < this.queryCateL.size || !res.data.length) {
            this.noMore = true;
            this.more = false;
          } else {
            this.more = true;
          }
        } else {
          this.loading = false;
        }
      } catch (err) {}
    },
    addMore() {
      this.queryCateL.page++;
      this.getArticleL();
    },
  },

  computed: {},
  watch: {},
  created() {
    this.getArticleL();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.content {
  position: relative;
  width: 1200px;
  margin: auto;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background: #eaeaea;
    z-index: 0;
  }
  .post {
    position: relative;
    margin-top: 100px;
    height: 440px;
    .img-container {
      display: inline-block;
      position: relative;
      width: 680px;
      height: 440px;
      overflow: hidden;
      border-radius: 10px;
      z-index: 1;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s;
        &:hover {
          transform: scale(1.1); //设置缩放比例
        }
      }
    }
    .info-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 600px;
      height: 400px;
      // border: 1px solid #eaeaea;
      // border-radius: 10px;
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .info {
        box-sizing: border-box;
        width: 500px;
        height: 400px;
        position: absolute;
        padding: 80px 100px 0 80px;
        border: 1px solid #eaeaea;
        border-radius: 6px;
      }
      .time {
        color: #999;
        font-size: 12px;
      }
      .title {
        margin-top: 8px;
        overflow: hidden;
        font-size: 24px;
        line-height: 30px;
        cursor: pointer;
        span {
          font-size: 24px;
          color: #262626;
          &:hover {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 5'%3E%3Cpath fill='none' stroke='%23333' d='M0 3.5c5 0 5-3 10-3s5 3 10 3 5-3 10-3 5 3 10 3'/%3E%3C/svg%3E")
              repeat-x 0 100%;
            background-size: 20px auto;
            animation: waveMove 1s infinite linear;
          }
        }
        @keyframes waveMove {
          from {
            background-position: 0 100%;
          }
          to {
            background-position: -20px 100%;
          }
        }
      }
      .describe {
        color: #666;
        font-size: 14px;
        line-height: 22px;
        margin-top: 10px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .post:nth-child(2n) {
    text-align: left;
    .info-container {
      right: 0;
    }
  }
  .post:nth-child(2n + 1) {
    text-align: right;
    .info-container {
      left: 0;
      text-align: left;
    }
  }
  .noMore {
    position: relative;
    z-index: 9;
    margin: 90px 0;
    text-align: center;
    color: #909090;
    height: 34px;
    line-height: 34px;
    background-color: #fff;
  }
  .border {
    position: relative;
    margin: 90px auto;
    text-align: center;
    // border: 1px solid #eaeaea;
    border-radius: 4px;
    height: 34px;
    width: 138px;
    color: #909090;
    background-color: #fff;
    z-index: 9;
    letter-spacing: 2px;
    overflow: hidden;
  }
  .border::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background: #c30000;
    animation: clippath 3s infinite linear;
  }
  .more {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 134px;
    height: 30px;
    border-radius: 3px;
    line-height: 28px;
    cursor: pointer;
    transform: translate(-2px, -2px);
    background: #fff;
  }
  @keyframes clippath {
    0%,
    100% {
      clip-path: inset(0 0 95% 0);
    }
    25% {
      clip-path: inset(0 95% 0 0);
    }
    50% {
      clip-path: inset(95% 0 0 0);
    }
    75% {
      clip-path: inset(0 0 0 95%);
    }
  }
}
@media screen and (max-width: 1200px) {
  .content {
    width: 900px;
    .post {
      margin-top: 60px;
      z-index: 1;

      .img-container {
        width: 480px;
        height: 310px;
        img {
          vertical-align: middle;
        }
      }
      .info-container {
        top: 10px;
        transform: translateY(0);
        width: 420px;
        height: 290px;
        .info {
          box-sizing: border-box;
          width: 420px;
          height: 290px;
          padding: 50px 60px 0;
          position: absolute;
          border: 1px solid #eaeaea;
          border-radius: 6px;
        }
      }
    }
    .post:nth-child(odd) {
      text-align: left;
      .info-container {
        right: 10px;
      }
    }
    .post:nth-child(even) {
      text-align: right;
      .info-container {
        left: 10px;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    width: 100%;
    .post {
      margin-top: 60px;
      z-index: 1;
      height: auto;
      .img-container {
        width: 680px;
        max-width: 100%;
        display: block;
        text-align: center;
        border: none;
        margin: auto;
        height: 400px;
        img {
          vertical-align: middle;
        }
      }
      .info-container {
        position: static;
        transform: translateX(0);
        border-bottom: 1px solid #eaeaea;
        height: auto;
        display: block;
        background: #fff;
        width: 100%;
        .info {
          position: static;
          width: 100%;
          margin: auto;
          height: auto;
          border: none;
          padding: 40px 20px;
          background: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 780px) {
  .content {
    width: 100%;
    .post {
      margin-top: 60px;
      .img-container {
        width: 100%;
        height: 250px;
        border-radius: 0;
      }
    }
  }
}
.footer {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  text-align: center;
  a {
    color: #999;
  }
}
</style>
